import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getChannelDetailData } from 'api/channel';
import { updateVideoLinkInfo } from 'api/scoreboard';

import { CautionIcon } from 'components/atoms/Icons';
import { NextLinkComposed } from 'components/atoms/Link';
import LiveChip from 'components/commons/LiveChip';
import MembersOnlyChip from 'components/commons/MembersOnlyChip';
import NotAllowedVideoTypeCard from 'components/commons/NotAllowedCard/NotAllowedVideoTypeCard';

import useActiveAuth from 'hooks/useActiveAuth';
import useAutoPlay from 'hooks/useAutoPlay';
import useCheckDevice from 'hooks/useCheckDevice';
import useNumberSystemConverter from 'hooks/useNumberSystemConverter';
import useValidateVideo from 'hooks/useValidateVideo';

import type { ChannelDetailDataType } from 'types/channel';

import { dayFormatter } from 'utils/dayFormatter';
import { matchThumbnailPicker } from 'utils/defaultThumbnailPicker';

import MatchBanner from './MatchBanner';
import MatchCardMenuButton from './MatchCardMenuButton';
import type { PublicMatchListCardProps } from './PublicMatchListCard.type';

const PublicMatchListCard = ({
  focusVideoIndex,
  idx,
  isMyPage = false,
  isFixedSize = false,
  matchListData,
  pageId,
  addPlayList,
  deletePlayList,
  handleVideoErrorCodeList,
}: PublicMatchListCardProps) => {
  const { user } = useActiveAuth();
  const router = useRouter();
  const {
    palette: { mode, deprecatedGray, gray },
  } = useTheme();
  const { t } = useTranslation('scoreboard');
  const { t: common_t } = useTranslation('common');
  const { isMobile } = useCheckDevice();

  const [thumbnailImg, setThumbnailImg] = useState(
    matchThumbnailPicker(matchListData.sportType),
  );

  const cardRef = useRef(null);
  const reactPlayerRef = useRef<ReactPlayer>(null);

  // ! 이전 thumbNailImageUrl값이 현재는 thumbnailUrl로 들어옴
  const {
    id: matchNumberId,
    channelName,
    date,
    dateAtUtc,
    disclosure,
    mainLink,
    matchUid: matchId,
    nickname,
    profileUrl,
    sportType,
    status,
    thumbnailUrl,
    title,
    views,
    notAllowed,
    canPlay: isPlayable,
  } = useMemo(() => matchListData, [matchListData]);

  const { canPlay, responseCode, isVideoLoading, videoData } = useValidateVideo(
    (isPlayable === null || (isMyPage && pageId === 'channel')) && mainLink
      ? mainLink
      : '',
  );

  const isReadyToPlay = useMemo(() => {
    const playCondition: boolean =
      isPlayable && status !== 'schedule' && user?.autoPlayEnabled;
    return {
      web: !isMobile && playCondition,
      mobile: isMobile && playCondition,
    };
  }, [isMobile, isPlayable, status, user?.autoPlayEnabled]);

  const isSearchCard = useMemo(
    () => pageId === 'search' && !isMobile,
    [pageId, isMobile],
  );

  const openChannelPage = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    router.push(`/channel/@${nickname}`);
  };

  const { isAutoPlay, mobileVideoControl, playVideo, stopVideo } = useAutoPlay(
    mainLink,
    isPlayable,
  );

  const convertedViews = useNumberSystemConverter(views);

  useEffect(() => {
    if (mainLink && isPlayable === null && !isVideoLoading) {
      try {
        if (videoData.noembed) {
          if (videoData.noembed.thumbnail_url) {
            setThumbnailImg(videoData.noembed.thumbnail_url);
            updateVideoLinkInfo({
              matchId: matchNumberId,
              mainLink,
              isExistThumbnail: videoData.noembed.thumbnail_url ? true : false,
              canPlay: canPlay,
              linkThumbnailUrl: videoData.noembed.thumbnail_url || null,
            });
            return;
          }

          if (videoData.noembed.error) {
            updateVideoLinkInfo({
              matchId: matchNumberId,
              mainLink,
              isExistThumbnail: false,
              canPlay: canPlay,
              linkThumbnailUrl: null,
            });
          }
        }
      } catch (error) {
        console.error('Video info update error', error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoLoading]);

  useEffect(() => {
    if (thumbnailUrl) {
      setThumbnailImg(thumbnailUrl.replace(/\(|\)/g, '\\$&'));
      return;
    }

    setThumbnailImg(matchThumbnailPicker(sportType));
  }, [sportType, thumbnailUrl]);

  useEffect(() => {
    if (isReadyToPlay.mobile) {
      let cardRefCurrent = cardRef.current;

      let debounceTimeout: NodeJS.Timeout | null = null;

      const observer = new IntersectionObserver(
        (entries) => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }

          debounceTimeout = setTimeout(() => {
            if (
              addPlayList &&
              deletePlayList &&
              focusVideoIndex !== undefined
            ) {
              mobileVideoControl(
                entries,
                idx,
                focusVideoIndex,
                addPlayList,
                deletePlayList,
              );
            }
          }, 200);
        },
        { threshold: 0.8, rootMargin: '-40px 0px' },
      );

      if (cardRef.current) {
        cardRefCurrent = cardRef.current;
        observer.observe(cardRef.current);
      }

      return () => {
        if (cardRefCurrent) observer.unobserve(cardRefCurrent);
        if (debounceTimeout) clearTimeout(debounceTimeout);
      };
    }
  }, [
    focusVideoIndex,
    idx,
    isReadyToPlay,
    addPlayList,
    deletePlayList,
    mobileVideoControl,
  ]);

  const showVideoError = useMemo(
    () =>
      isMyPage &&
      pageId === 'channel' &&
      !canPlay &&
      mainLink &&
      !isVideoLoading,
    [isMyPage, isVideoLoading, mainLink, pageId, canPlay],
  );

  useEffect(() => {
    if (showVideoError && handleVideoErrorCodeList) {
      handleVideoErrorCodeList(responseCode);
    }
  }, [handleVideoErrorCodeList, responseCode, showVideoError]);

  const { data: channelDetailData } = useQuery<ChannelDetailDataType>({
    queryFn: () => getChannelDetailData(`@${nickname}`),
    queryKey: ['channelDetailData', `@${nickname}`],
    staleTime: 1000 * 60 * 5,
    enabled: notAllowed,
  });

  if (notAllowed)
    return (
      <NotAllowedVideoTypeCard
        type="match"
        channelId={channelDetailData?.channelId || 0}
        title={title}
        thumbnailUrl={thumbnailUrl}
        sportType={sportType}
        createdAt={dateAtUtc || date}
        sx={{
          width: isFixedSize
            ? { xs: '208px', sm: '320px', lg: '400px' }
            : '100%',
          minWidth: isFixedSize
            ? { xs: '208px', sm: '320px', lg: '400px' }
            : undefined,
          ascentRatio: '16/9',
          cursor: { sm: 'pointer' },
          mb: 2,
        }}
      />
    );

  return (
    <Box
      className="gtm-match-detail-btn"
      ref={cardRef}
      component={NextLinkComposed}
      to={{ pathname: `/match/${matchId}` }}
      onMouseEnter={() => {
        if (isReadyToPlay.web) playVideo();
      }}
      onMouseLeave={() => {
        if (isReadyToPlay.web) stopVideo();
      }}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: isSearchCard ? 'row' : 'column',
        width: isFixedSize ? { xs: '208px', sm: '320px', lg: '400px' } : '100%',
        minWidth: isFixedSize
          ? { xs: '208px', sm: '320px', lg: '400px' }
          : undefined,
        borderRadius: isMobile && !isFixedSize ? 0 : '6px',
        scrollSnapAlign: 'start',
        scrollSnapStop: 'normal',
        mb: 2,

        ...(isAutoPlay && {
          transition: ' 0.5s ease-in-out',
          borderRadius: 0,
        }),
      }}
    >
      <Box
        className="gtm-match-video"
        sx={{ position: 'relative', mr: isSearchCard ? 2 : 0 }}
      >
        <Box
          sx={{
            aspectRatio: '16 / 9',
            mb: { xs: 1, sm: 1.5 },
            width: '100%',
            height: isSearchCard ? { sm: '160px', md: '200px' } : 'auto',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: isAutoPlay ? 'black' : 'transparent',
            borderRadius:
              isMobile && !isFixedSize && pageId !== 'channel' ? 0 : '6px',

            ...(isAutoPlay && {
              transition: ' 0.5s ease-in-out',
              borderRadius: 0,
              border: 'none',
              borderColor: 'transparent',
            }),

            ...(status !== 'schedule' && {
              borderStyle: 'solid',
              borderWidth:
                isMobile && !isFixedSize && pageId !== 'channel'
                  ? '1px 0'
                  : '1px',
              borderColor: 'divider',
            }),
          }}
        >
          {user?.autoPlayEnabled && isPlayable && isAutoPlay && mainLink && (
            <ReactPlayer
              playsinline
              controls
              playing={isAutoPlay}
              ref={reactPlayerRef}
              url={mainLink}
              muted={true}
              width="100%"
              height="100%"
            />
          )}

          <Box
            sx={{
              display: !isAutoPlay ? 'block' : 'none',
              aspectRatio: '16 / 9',
              backgroundColor: mode === 'light' ? 'white' : deprecatedGray[15],
              borderRadius:
                isMobile && !isFixedSize && pageId !== 'channel' ? 0 : '6px',
              position: 'absolute',
              width: '100%',
              top: 0,
              transition: 'transform 0.5s ease-in-out',

              '&:hover': {
                transform: !isAutoPlay ? 'scale(1.1)' : 'scale(1)',
              },
            }}
          >
            <Image
              src={thumbnailImg}
              alt={title}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              onError={() => {
                const altThumbnailUrl = matchThumbnailPicker(sportType);
                setThumbnailImg(altThumbnailUrl);
              }}
            />
          </Box>
          {!isAutoPlay && (
            <MatchBanner
              matchListData={matchListData}
              isPlayable={isPlayable}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flex: 1,
          mx: pageId === 'channel' ? 0 : { xs: 1, sm: 0 },
        }}
      >
        {pageId !== 'channel' && !isSearchCard && (
          <Avatar
            className="gtm-channel-detail-btn"
            src={profileUrl ?? ''}
            alt={`${channelName}'s profile image`}
            onClick={(e) => openChannelPage(e)}
            sx={{
              height: { xs: '30px', sm: '36px' },
              width: { xs: '30px', sm: '36px' },
              mr: { xs: '8px', sm: '16px' },
            }}
          />
        )}

        <Box
          sx={{
            flex: 1,
            p: isSearchCard ? { sm: 0, md: 1.5 } : 0,
            pl: pageId !== 'channel' && !isSearchCard ? 0 : 0.5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            <CautionIcon sx={{ display: showVideoError ? 'block' : 'none' }} />
            <Typography
              variant={isMobile ? 'h6Bold' : 'h5Bold'}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: { xs: 1, sm: 2 },
                WebkitBoxOrient: 'vertical',
                overflowWrap: 'anywhere',
                wordBreak: 'break-all',
                mb: '4px',
              }}
            >
              {title}
            </Typography>
          </Box>

          <Typography
            variant={isMobile ? 'body3' : 'body2'}
            sx={{ color: 'text.caption', mb: 0.5 }}
          >
            {dayFormatter(dateAtUtc || date, `YYYY.MM.DD`, {
              locale: router.locale,
              isZuluTime: true,
            })}
            {isMyPage && pageId === 'channel'
              ? `・${common_t(`visibility.${disclosure}`)}`
              : ''}
            {`・${t('view', { view: convertedViews })}`}
          </Typography>

          {pageId !== 'channel' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: isSearchCard ? 1 : 0,
              }}
            >
              {isSearchCard && (
                <Avatar
                  className="gtm-channel-detail-btn"
                  src={profileUrl ?? ''}
                  alt={`${channelName}'s profile image`}
                  onClick={(e) => openChannelPage(e)}
                  sx={{ height: '32px', width: '32px', m: 0.5 }}
                />
              )}
              <Typography
                className="gtm-channel-detail-btn"
                variant={isMobile ? 'body3' : 'body2'}
                onClick={(e) => openChannelPage(e)}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-all',
                  color: mode === 'dark' ? deprecatedGray[70] : gray[500],
                  ':hover': {
                    color: 'themeInverseColor',
                  },
                }}
              >
                {channelName}
              </Typography>
            </Box>
          )}

          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}
          >
            {disclosure === 'membership' ? <MembersOnlyChip /> : null}
            {status === 'live' ? <LiveChip /> : null}
          </Box>
        </Box>

        <MatchCardMenuButton
          isMyPage={isMyPage}
          matchListData={matchListData}
          thumbnailImg={thumbnailImg}
        />
      </Box>
    </Box>
  );
};

export default PublicMatchListCard;
